import React from "react";
import { FaFileAlt } from "react-icons/fa";
import { useLanguage } from "./Language"; // Import the Language hook

const Footer = () => {
  const { content } = useLanguage(); // Get the correct language content

  if (!content || !content.general || !content.footer) return null; // Prevent errors if content isn't loaded yet

  const general = content.general;
  const impressum = general.navbar_social_links.impressum;

  const iconStyle = {
    fontSize: "0.8rem", // Adjust the size as needed
    marginRight: "1.2rem", // Add space between icon and text
  };

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="copyright-box">
              <p className="copyright">
                {content.footer.line_one}{" "}
                <a href="https://github.com/nexox13">
                  <strong>
                    {general.first_name} {general.middle_name} {general.last_name}
                  </strong>
                </a>
                .
              </p>
              <div className="credits">
                {content.footer.copyright_line} &copy;
              </div>
              <div className="fork-stars">
                <a href={impressum} title="View Impressum">
                  <FaFileAlt style={iconStyle} /> {/* Removed incorrect href inside the icon */}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
