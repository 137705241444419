import React from "react";
import { Fade } from "react-awesome-reveal";
import { useLanguage } from "./Language"; // Import the Language hook

const Education = () => {
  const { content } = useLanguage(); // Get the correct language content

  if (!content || !content.academics) return null; // Prevent errors if content isn't loaded yet

  const education = content.academics;

  return (
    <Fade triggerOnce={true}>
      <section id="education">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box">
                <h3 className="title-a" id="education">
                  {education.section.title} 
                  <div className="line-mf"></div>
                </h3>
                <p className="subtitle-a">{education.section.description}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                {education.years.map((year, index) => (
                  <div className="col-md-6" key={index}>
                    <Fade
                      delay={index * 200}
                      cascade={false}
                      triggerOnce={true}
                    >
                      <div className="education">
                        <table>
                          <thead>
                            <tr>
                              <th>{year.semester} {year.year}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{year.description}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Fade>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fade>
  );
};

export default Education;
