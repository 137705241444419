import React from "react";
import contentData from "../content.json";
import { FaGithub } from "react-icons/fa";
import { RiArrowGoBackLine } from "react-icons/ri";

const Impressum = () => {
    const impressum = contentData.impressum;

    return (
        <section id="impressum">
            <div className="container">
                {/* Top Links with Icons */}
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <div className="center-links">
                            <a href="/" className="btn-icon" title="Go Back to Home">
                                <RiArrowGoBackLine  size={24} />
                            </a>
                            <span className="separator"> | </span>
                            <a
                                href={contentData.general.navbar_social_links.github}
                                className="btn-icon"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="View GitHub Page"
                            >
                                <FaGithub size={24} />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="title-box">
                            <h3 className="title-a">
                                {impressum.section.title}
                                <div className="line-mf"></div>
                            </h3>
                            <p className="subtitle-a">{impressum.section.description}</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="impressum-contact">
                            <p><strong>Name:</strong> {impressum.impressum_text.find(item => item.label === "Name")?.value}</p>
                            <p><strong>Address:</strong> {impressum.impressum_text.find(item => item.label === "Address")?.value}</p>
                            <p><strong>Email:</strong> {impressum.impressum_text.find(item => item.label === "Email")?.value}</p>
                            <p><strong>Phone:</strong> {impressum.impressum_text.find(item => item.label === "Phone")?.value}</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="impressum-content">
                            {impressum.impressum_text
                                .filter(item => !["Name", "Address", "Email", "Phone"].includes(item.label))
                                .map((item, index) => (
                                    <div key={index} className="impressum-item">
                                        <h4 className="impressum-label">{item.label}</h4>
                                        <p className="impressum-value">{item.value}</p>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>

                {/* Bottom Links (Optional, If Needed) */}
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <div className="center-links">
                            <a href="/" className="btn-icon" title="Go Back to Home">
                                <RiArrowGoBackLine  size={24} />
                            </a>
                            <span className="separator"> | </span>
                            <a
                                href={contentData.general.navbar_social_links.github}
                                className="btn-icon"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="View GitHub Page"
                            >
                                <FaGithub size={24} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Impressum;
