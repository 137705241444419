// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";

import Navbar from "./components/Navbar";
import Linkbar from "./components/Linkbar";
import Intro from "./components/Intro";
import About from "./components/About";
import Education from "./components/Education";
import WorkExperience from "./components/WorkExperience";
import SideProjects from "./components/SideProjects";
import Footer from "./components/Footer";
import Error404 from "./components/Error404";
import ImpressumPage from "./components/Impressum";
import { LanguageProvider, useLanguage } from "./components/Language";
import Switch from "./components/Switch";

import "./styles/style.css";


function AppContent() {
  // Use the language context
  const { content } = useLanguage();
  const { intro_screen, about_me, academics, experience, projects } = content;

  const location = useLocation();
  return (
    <div>
      {location.pathname === "/" && <Switch />}
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <Navbar />
              <Linkbar />
              {intro_screen.section.enable_section && <Intro />}
              {about_me.section.enable_section && <About />}
              {academics.section.enable_section && <Education />}
              {experience.section.enable_section && <WorkExperience />}
              {projects.section.enable_section && <SideProjects />}
              <Footer />
            </div>
          }
        />
        <Route path="/impressum" element={<ImpressumPage />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <LanguageProvider>
      <Router>
        <AppContent />
      </Router>
    </LanguageProvider>
  );
}

export default App;
