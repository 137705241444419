// components/Language.js
import React, { createContext, useState, useContext } from "react";
import contentEN from "../content.json"; // English content
import contentDE from "../inhalt.json"; // German content

// Create a context to store the selected language
const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  // Get language from localStorage (if available) or default to English
    const storedLanguage = localStorage.getItem("language") || "en";
    const [language, setLanguage] = useState(storedLanguage);

  // Function to switch language and store it in localStorage
    const toggleLanguage = () => {
        const newLanguage = language === "en" ? "de" : "en";
        setLanguage(newLanguage);
        localStorage.setItem("language", newLanguage);
    };

  // Choose content based on the selected language
    const content = language === "en" ? contentEN : contentDE;

    return (
        <LanguageContext.Provider value={{ language, toggleLanguage, content }}>
        {children}
        </LanguageContext.Provider>
    );
};

// Custom hook to access language context
export const useLanguage = () => useContext(LanguageContext);
