import React from "react";
import { FaComment } from "react-icons/fa";
import { Fade } from "react-awesome-reveal";
import { useLanguage } from "./Language"; // Import the Language hook

const Intro = () => {
  const { content } = useLanguage(); // Get the correct language content

  if (!content || !content.intro_screen || !content.general) return null; // Prevent errors if content isn't loaded yet

  const introContent = content.intro_screen;
  const generalContent = content.general;

  return (
    <div className="container"> {/* Fixed class to className */}
      <div className="row"> {/* Fixed class to className */}
        <div className="col-sm-12">
          <section className="intro_section"> {/* Fixed class to className */}
            <Fade direction="down" triggerOnce={true}>
              <h1>{introContent.main_header}</h1>
              <p>
                <strong>{introContent.main_subtitle}</strong>
              </p>
            </Fade>
            <Fade triggerOnce={true}>
              <div className="introParagraph">
                {introContent.intro_bio.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>
            </Fade>
            <Fade direction="up" triggerOnce={true}>
              <h3>
                <a href={`mailto:${generalContent.navbar_social_links.email}`}>
                  <FaComment /> {introContent.email_button}
                </a>
              </h3>
            </Fade>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Intro;
