import React from "react";
import { Fade } from "react-awesome-reveal";
import { useLanguage } from "./Language"; // Import the Language hook

const Navbar = () => {
  const { content } = useLanguage(); // Get the correct language content

  if (!content) return null; // Prevent errors if content isn't loaded yet

  const sections = [
    {
      id: "aboutme",
      text: content.about_me.section.navbar_name,
      enable: content.about_me.section.enable_section,
    },
    {
      id: "education",
      text: content.academics.section.navbar_name,
      enable: content.academics.section.enable_section,
    },
    {
      id: "experience",
      text: content.experience.section.navbar_name,
      enable: content.experience.section.enable_section,
    },
    {
      id: "projects",
      text: content.projects.section.navbar_name,
      enable: content.projects.section.enable_section,
    },
  ];

  return (
    <div className="navlist">
      <Fade cascade damping={0.2} triggerOnce={true}>
        <ul>
          <li>
            <a href="https://www.nexo.li">/Home</a>
          </li>
          {sections.map((section) =>
            section.enable ? (
              <li key={section.id}>
                <a href={`#${section.id}`}>{section.text}</a>
              </li>
            ) : null
          )}
        </ul>
      </Fade>
    </div>
  );
};

export default Navbar;
